import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import { useFormik } from "formik";
import { Button, RenderIf } from "shared/components";
import { useCreateCourseRatingMutation } from "redux/api/library";
import { labels } from "./data";
import "./Modal.scss";

const initialValues = {
  rating: 0,
};

const RateModal = ({ show, onHide, hubDevCourseId }) => {
  const { t } = useTranslation();

  const [value, setValue] = useState(0);
  const [rateCourse, { isSuccess, isLoading }] =
    useCreateCourseRatingMutation();

  const formik = useFormik({
    initialValues,
    onSubmit: () => {
      const formData = new FormData();
      formData.append("HubDevCourseId", hubDevCourseId);
      formData.append("Rating", Number(value));
      rateCourse(formData);
    },
  });
  useEffect(() => {
    if (isSuccess) {
      formik.resetForm();
      onHide();
      setValue(null);
    }
  }, [isLoading]);

  return (
    <Modal keyboard={false} backdrop="static" show={show} onHide={onHide}>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{t("rateCourse")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="rateModalBody">
          <Box
            sx={{
              width: 200,
              display: "flex",
              alignItems: "center",
            }}>
            <Rating
              name="rating"
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
            />
            <Box sx={{ ml: 2 }}>
              {labels[value] !== undefined ? labels[value] : ""}
            </Box>
          </Box>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="pt_14_26 font_14 align-items-center d-flex"
            background="#057953"
            type="submit"
            disabled={isLoading || !value}>
            {t("rate")}
            <RenderIf condition={isLoading}>
              <div class="spinner-border" role="status"></div>{" "}
            </RenderIf>
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default RateModal;
