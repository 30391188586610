import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import Member from "shared/media/user/userIcon.png";
import { RenderIf, Button, SearchInput, UserCard } from "shared/components";
import { updateObject } from "shared/helper/updateObject";
import { useGetAllUsersQuery } from "redux/api/siteUsers";
import { useSendCourseMutation } from "redux/api/library";
const label = { inputProps: { "aria-label": "Checkbox demo" } };
const { REACT_APP_BASE_PHOTO_URL } = process.env;
const SendCourse = ({ show, onHide }) => {
  const { t } = useTranslation();
  const { categoryId } = useParams();
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedUserEmails, setSelectedUserEmails] = useState([]);
  const [filterInputs, setFilterInputs] = useState({
    firstName: "",
  });
  const [searchResults, setSearchResults] = useState([]);
  const { data: siteUsers } = useGetAllUsersQuery({
    name: filterInputs,
  });
  const [
    sendCourse,
    { isSuccess: sendSuccess, isLoading: sendLoading, error },
  ] = useSendCourseMutation();
  const sendNewsPost = () => {
    const data = {
      courseId: Number(categoryId),
      emails: selectedUserEmails,
      ids: selectedUserIds,
    };
    selectedUserEmails.length && sendCourse(data);
  };
  const filterInputsUpdate = (e) => {
    const { name, value } = e.target;
    const updateFiltersInput = updateObject(filterInputs, {
      [name]: value,
    });
    setFilterInputs(updateFiltersInput);
    let filteredUsers = [];
    if (value === "") {
      filteredUsers = [];
    } else {
      filteredUsers = siteUsers?.data?.filter((user) =>
        `${user.firstName} ${user.lastName}`
          .toLowerCase()
          .includes(updateFiltersInput[name].toLowerCase())
      );
    }
    setSearchResults(filteredUsers);
  };

  const handleCheckboxChange = (event, email, id) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectedUserEmails((prevSelectedUserEmails) => [
        ...prevSelectedUserEmails,
        email,
      ]);
      setSelectedUserIds((prevSelectedUserEmails) => [
        ...prevSelectedUserEmails,
        id,
      ]);
    } else {
      setSelectedUserEmails((prevSelectedUserEmails) =>
        prevSelectedUserEmails.filter((item) => item !== email)
      );
      setSelectedUserIds((prevSelectedUserEmails) =>
        prevSelectedUserEmails.filter((item) => item !== id)
      );
    }
  };

  const onHideModal = () => {
    onHide(false);
    setSelectedUserEmails([]);
    setSearchResults([]);
    setSelectedUserIds([]);
    setFilterInputs({
      firstName: "",
    });
  };
  useEffect(() => {
    if (sendSuccess) {
      toast.success("Send successfully !", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      onHide(false);
      setSelectedUserEmails([]);
      setFilterInputs({
        firstName: "",
      });
      setSearchResults([]);
      setSelectedUserIds([]);
    }
  }, [sendSuccess]);
  useEffect(() => {
    if (error) {
      toast.error("Please try again !", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [error]);

  return (
    <Modal keyboard={false} backdrop="static" show={show} onHide={onHideModal}>
      <Modal.Header closeButton>
        <Modal.Title>{t("sendPost")}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="sendList">
          <SearchInput
            filterInputsUpdate={filterInputsUpdate}
            filterInputs={filterInputs?.firstName}
            setFilterInputs={setFilterInputs}
            placeholder={t("search")}
            name="firstName"
          />
          <div className="sendListBody">
            {searchResults?.map((c) => (
              <div key={c?.id} className="sendListBodyItem d-flex">
                <div className="sendListBodyItemLeft">
                  <UserCard
                    image={
                      c?.profilePhotoUrl
                        ? `${REACT_APP_BASE_PHOTO_URL}${c?.profilePhotoUrl}`
                        : Member
                    }
                    userName={c?.firstName}
                    userLastName={c?.lastName}
                    profession={c?.position}
                  />
                </div>
                <div className="sendListBodyItemRight">
                  <Checkbox
                    {...label}
                    color="success"
                    checked={selectedUserEmails.includes(c?.email)}
                    onChange={(e) => handleCheckboxChange(e, c?.email, c?.id)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="pt_14_26 font_14 align-items-center d-flex"
          onClick={sendNewsPost}
          background="#057953"
          type="submit"
          disabled={!selectedUserIds?.length || sendLoading}>
          {t("share")}
          <RenderIf condition={sendLoading}>
            <div class="spinner-border" role="status"></div>{" "}
          </RenderIf>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SendCourse;
