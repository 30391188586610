import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SideMenu, Header } from "shared/layout";
import { RenderIf } from "shared/components";
import { Urls } from "shared/constants/url";
import Photo1 from "shared/media/interestImage.png";
import Photo2 from "shared/media/interestImageSecond.png";
import { useDetailedInfoByIdQuery } from "redux/api/siteUsers";
import { setIsShow } from "redux/feature/user";
import "./Cover.scss";

const Cover = ({ children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useSelector((state) => state.user.user);
  const { data: userData } = useDetailedInfoByIdQuery({ id: id });
  return (
    <div className="d-flex flex-column flex-root">
      <SideMenu />
      <div className="d-flex flex-column flex-row-fluid wrapper">
        <Header />
        <div className="content d-flex flex-column flex-column-fluid">
          <div className="body-wrapper">
            {children}
            <RenderIf condition={userData?.interests?.length < 3}>
              <div
                onClick={() => {
                  navigate(Urls.POSTS_URL + `/${id}`);
                  dispatch(setIsShow(true));
                }}
                className="astronaut-container">
                <img src={Photo1} alt="Astronaut" className="astronaut-image" />
                <img
                  src={Photo2}
                  alt="Speech Bubble"
                  className="bubble-image"
                />
                <p className="bubble-text">{t("popupText")}</p>
              </div>
            </RenderIf>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cover;
