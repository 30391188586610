import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ClickMore, RenderIf } from "shared/components";
import { StarIcon } from "shared/components/Icons";
import "../LibraryCard/Card.scss";

const MyDevCard = ({
  title,
  svg,
  onClick,
  className,
  titles,
  isEditText,
  setText,
  text,
  formik,
  getFieldProps,
  getInputCheck,
  isEditable,
  setShow,
  setFieldValue,
  setSelectedId,
}) => {
  const cardRef = useRef();
  const { t } = useTranslation();
  const handleOutsideClick = (event) => {
    if (cardRef.current && !cardRef.current.contains(event.target)) {
      if (isEditable) {
        formik.handleSubmit();
        setShow(false);
        setSelectedId(null);
      } else if (isEditText) {
        formik.handleSubmit();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isEditText, isEditable]);

  return (
    <>
      <RenderIf condition={!isEditText && !isEditable}>
        <div
          ref={cardRef}
          onClick={onClick}
          className={`libraryCard ${className}`}>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <RenderIf condition={svg}>
                <div className="libraryCardIcon">
                  <StarIcon />
                </div>
              </RenderIf>
              <h4>{title}</h4>
            </div>
            <ClickMore buttonTitles={titles} />
          </div>
        </div>
      </RenderIf>
      <RenderIf condition={isEditText}>
        <div ref={cardRef} className={`libraryCard ${className}`}>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <RenderIf condition={svg}>
                <div className="libraryCardIcon">
                  <StarIcon />
                </div>
              </RenderIf>

              <input
                autoFocus
                className="libraryCardInput"
                type="text"
                id="name"
                name="name"
                value={text}
                placeholder={t("text")}
                onChange={(e) => {
                  setText(e.target.value);
                  setFieldValue("name", text);
                }}
                errors={getInputCheck("name")}
              />
            </div>
          </div>
        </div>
      </RenderIf>
      <RenderIf condition={isEditable}>
        <div ref={cardRef} className={`libraryCard ${className}`}>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <RenderIf condition={svg}>
                <div className="libraryCardIcon">
                  <StarIcon />
                </div>
              </RenderIf>

              <input
                autoFocus
                className="libraryCardInput"
                type="text"
                id="title"
                name="title"
                placeholder={t("text")}
                {...getFieldProps("title")}
                errors={getInputCheck("title")}
              />
            </div>
            {/* <ClickMore buttonTitles={titles} /> */}
          </div>
        </div>
      </RenderIf>
    </>
  );
};

export default MyDevCard;
