export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const updateArray = (oldArray, updatedProperties) => {
  return [...oldArray, ...updatedProperties];
};
export const getTitleByComplexity = (complexity) => {
  let title;

  if (complexity === 1) {
    title = "Easy";
  } else if (complexity === 2) {
    title = "Medium";
  } else if (complexity === 3) {
    title = "Hard";
  } else if (complexity === 4) {
    title = "Very Hard";
  }

  return title;
};
export const handleDownload = (id, fileName, getFile) => {
  let fileNameWithExtension = fileName.substring(fileName.lastIndexOf("/") + 1);
  let fileParts = fileNameWithExtension.split(".");
  let fileExtension = fileParts[1];
  getFile(id).then((res) => {
    const url = window.URL.createObjectURL(
      new Blob([res?.data, { type: `application/${fileExtension}` }])
    );
    var a = document.createElement("a");
    a.href = url;
    a.download = `${fileNameWithExtension}`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  });
};
export const groupOptionsByAlphabet = (options) => {
  const groupedOptions = options?.reduce((acc, option) => {
    const firstLetter = option?.label[0].toUpperCase();
    if (!acc[firstLetter]) {
      acc[firstLetter] = {
        label: firstLetter,
        options: [],
      };
    }
    acc[firstLetter].options.push(option);
    return acc;
  }, {});

  return Object.values(groupedOptions);
};
