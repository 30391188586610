import React, { useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Member from "shared/media/user/userIcon.png";
import { logout } from "redux/feature/user";
import { Urls } from "shared/constants/url";
import PashaHubLogo from "shared/media/logos/PashaHubLogo.png";
import CrocusoftLogo from "shared/media/logos/image.png";
import { RenderIf } from "shared/components";
import { Login } from "shared/components/Icons";
import { useDetailedInfoByIdQuery } from "redux/api/siteUsers";
import { useLazyDetailedInfoByIdQuery } from "redux/api/siteUsers";
import { useGetLinksQuery } from "redux/api/additionalLinks";
import ReturnerData from "./data";
import "./SideMenu.scss";

const { REACT_APP_BASE_PHOTO_URL, REACT_APP_BASE_PHOTO_URL_MAIN } = process.env;
const SideMenu = () => {
  const { data } = ReturnerData();
  const { id } = useSelector((state) => state.user.user);
  const { t } = useTranslation();
  const { data: userInfo } = useDetailedInfoByIdQuery({ id: id });
  const [detailedInfoById, { data: userData }] = useLazyDetailedInfoByIdQuery();

  const { data: linkData } = useGetLinksQuery();
  const location = useLocation();
  const pathName = location.pathname;
  const dispatch = useDispatch();

  const logoutHandler = () => {
    dispatch(logout());
    localStorage.removeItem("showModal");
    detailedInfoById({
      id: id,
    });
  };

  useEffect(() => {
    if (userInfo?.locked) {
      logoutHandler();
    }
  }, [userInfo?.locked]);

  return (
    <div className="aside aside-left aside-fixed flex-column">
      <div className="brand flex-column-auto ">
        <Link className="brand-logo" to={Urls.LIBRARY_URL}>
          <img src={PashaHubLogo} alt="" width="100px" height="40px" />
        </Link>
      </div>
      <div className="menu-nav-border">
        <div className="menu-nav d-flex">
          <Link to={Urls.POSTS_URL + `/${id}`}>
            <img
              alt="user"
              width="64px"
              height="64px"
              src={
                userInfo?.profilePhotoUrl
                  ? `${REACT_APP_BASE_PHOTO_URL}${userInfo?.profilePhotoUrl}`
                  : Member
              }
            />
          </Link>
          <div className="menu-nav_div">
            <Link
              className={`menu-nav_text ${
                pathName === Urls.SETTINGS_URL ? "span-active" : ""
              }`}
              to={Urls.SETTINGS_URL}>
              {t("settings")}
            </Link>

            <Link
              className={`menu-nav_text pt-2 ${
                pathName.startsWith(Urls.POSTS_URL) ? "span-active" : ""
              }`}
              to={Urls.POSTS_URL + `/${id}`}>
              {t("profile")}
            </Link>
          </div>
        </div>
      </div>
      <div className="menu-body-main">
        <div>
          <ul className="menu-body">
            {data?.map((c) => (
              <li
                key={c?.id}
                className={`menu-body_item ${
                  pathName === c?.url || pathName.startsWith(`${c?.url}/`)
                    ? "li-active"
                    : ""
                }`}>
                <Link to={c?.url}>
                  <div
                    className={`${
                      pathName === c?.url || pathName.startsWith(`${c?.url}/`)
                        ? "icon-active"
                        : ""
                    }`}>
                    <RenderIf condition={c?.img} renderElse={c?.icon}>
                      <img src={c?.img} alt="logo" />
                    </RenderIf>
                  </div>

                  <Tooltip
                    placement="right"
                    title={
                      <span
                        style={{
                          fontWeight: 400,
                          fontSize: "12px",
                        }}>
                        {c?.text}
                      </span>
                    }
                    className={`${
                      pathName === c?.url || pathName.startsWith(`${c?.url}/`)
                        ? "span-active"
                        : ""
                    }`}>
                    <span
                      className={`${
                        pathName === c?.url || pathName.startsWith(`${c?.url}/`)
                          ? "span-active"
                          : ""
                      }`}>
                      {t(c?.title)}
                    </span>
                  </Tooltip>
                </Link>
              </li>
            ))}
          </ul>
          <ul className="menu-footer">
            <RenderIf condition={linkData?.data?.length}>
              <li className="menu-footer_item">
                <span>{t("additional-links")}</span>
              </li>
              <li className="menu-footer_item">
                {linkData?.data?.map((c) => (
                  <div key={c?.id} className="d-flex align-items-center mt-3">
                    <img
                      src={`${REACT_APP_BASE_PHOTO_URL_MAIN}${c?.fileUrl}`}
                      alt="logo"
                    />

                    <Link to={c?.url} target="_blank">
                      <p>{c?.name}</p>
                    </Link>
                  </div>
                ))}
              </li>
            </RenderIf>
            <li className="menu-footer_item mt-3">
              <button className="menu-footer_button" onClick={logoutHandler}>
                <Login />
                {t("signOut")}
              </button>
            </li>
          </ul>
        </div>
        <Link to="https://crocusoft.com/" target="blank" className="ms-2 link">
          <div style={{ marginTop: "140px" }}>
            <span
              style={{
                fontSize: "14px",
                color: "black",
              }}>
              Designed & Developed by
            </span>
            <img
              src={CrocusoftLogo}
              alt="Crocusoft-logo"
              style={{
                width: "25px",
                height: "25px",
                objectFit: "cover",
              }}
            />
            <span style={{ fontWeight: "600" }}>Crocusoft</span>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default SideMenu;
