import {
  Barcode,
  Book,
  CalendarSearch,
  Messages2,
  People,
} from "shared/components/Icons";
import { useTranslation } from "react-i18next";

import { Urls } from "shared/constants/url";

const ReturnerData = () => {
  const { t } = useTranslation();
  const data = [
    {
      id: 1,
      title: t("HUBDev"),
      url: Urls.LIBRARY_URL,
      icon: <Book />,
      text: t("libraryDescription"),
      img: "/media/rocket.gif",
    },
    {
      id: 2,
      title: t("discussion"),
      url: Urls.DISCUSSIONS_URL,
      icon: <Messages2 />,
      text: t("discussionsDescription"),
      img: "/media/customer.gif",
    },
    {
      id: 3,
      title: t("community"),
      url: Urls.COMMUNITY_URL,
      icon: <People />,
      text: t("communityDescription"),
      img: "/media/diagram.gif",
    },
    {
      id: 4,
      title: t("news"),
      url: Urls.NEWS_URL,
      icon: <Barcode />,
      text: t("newsDescription"),
      img: "/media/live.gif",
    },

    {
      id: 5,
      title: t("events"),
      url: Urls.EVENTS_URL,
      icon: <CalendarSearch />,
      text: t("eventsDescription"),
      img: "/media/calendar.gif",
    },
  ];

  return {
    data,
  };
};
export default ReturnerData;
