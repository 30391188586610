import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Route, Routes, Navigate } from "react-router-dom";
import { Urls } from "shared/constants/url";

const Layout = React.lazy(() => import("./About/Layout"));
const ProfileLayout = React.lazy(() => import("./Profile/Layout"));
const AboutUs = React.lazy(() => import("./About/AboutUs"));
const ReviewStories = React.lazy(() => import("./About/Reviews-Stories"));
const ValuesEthics = React.lazy(() => import("./About/Values-Ethics"));
const Contributors = React.lazy(() => import("./About/Contributors"));
const Partners = React.lazy(() => import("./About/Partners"));
const Contact = React.lazy(() => import("./Contact"));
const Home = React.lazy(() => import("./Home/Dashboard"));
const News = React.lazy(() => import("./News"));
const NewsInner = React.lazy(() => import("./News/Inner"));
const Discussions = React.lazy(() => import("./Discussions"));
const Events = React.lazy(() => import("./Events"));
const EventsInner = React.lazy(() => import("./Events/Inner"));
const DiscussionInner = React.lazy(() => import("./Discussions/Detail"));
const Community = React.lazy(() => import("./Community"));
const CommunityInner = React.lazy(() => import("./Community/Inner"));
const Library = React.lazy(() => import("./Library"));
const CategoryInner = React.lazy(() =>
  import("./Library/Tabs/Catalouge/Inner")
);
const MyDevPlaylist = React.lazy(() => import("./Library/Tabs/MyDev/Inner"));
const Settings = React.lazy(() => import("./Settings"));
const Notifications = React.lazy(() => import("./Notifications"));
const Messages = React.lazy(() => import("./Messages"));
const ProfileAbout = React.lazy(() => import("./Profile/ProfileAbout"));
const Posts = React.lazy(() => import("./Profile/Posts"));
const SearchResults = React.lazy(() => import("./SearchResults"));
const Suggestion = React.lazy(() => import("./Suggestion"));
const PartnerInner = React.lazy(() => import("./About/Partners/Inner"));
const CommunityDiscussionInner = React.lazy(() =>
  import("./Community/Inner/Detail")
);
const InterestedEvents = React.lazy(() =>
  import("./Profile/Interested-Events")
);
const FollowedCommunities = React.lazy(() =>
  import("./Profile/Followed-Communities")
);
const CommunityEvent = React.lazy(() =>
  import("./Community/Inner/CommunityEvent")
);

const PriveRoots = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route path={Urls.HOME_URL} element={<Library />} />
      <Route path="*" element={<Navigate to={Urls.LIBRARY_URL} />} />

      <Route path={Urls.ABOUT_URL} element={<Layout />}>
        <Route path="" element={<Navigate to={Urls.ABOUT_US_URL} replace />} />
        <Route path={Urls.ABOUT_US_URL} element={<AboutUs />} />
        <Route path={Urls.CONTRIBUTORS_URL} element={<Contributors />} />
        <Route path={Urls.REVIEWS_STORIES_URL} element={<ReviewStories />} />
        <Route path={Urls.VALUE_ETHICS_URL} element={<ValuesEthics />} />
        <Route path={Urls.PARTNERS_URL} element={<Partners />} />
        <Route
          path={Urls.PARTNERS_URL + "/:partnerId"}
          element={<PartnerInner />}
        />
      </Route>
      <Route path={Urls.NEWS_URL} element={<News />} />
      <Route path={Urls.NEWS_URL + "/:newsId"} element={<NewsInner />} />
      <Route path={Urls.CONTACT_URL} element={<Contact />} />
      <Route path={Urls.SUGGESTION_URL} element={<Suggestion />} />

      <Route path={Urls.DISCUSSIONS_URL} element={<Discussions />} />
      <Route
        path={Urls.DISCUSSIONS_URL + "/:discussionId"}
        element={<DiscussionInner />}
      />

      <Route path={Urls.EVENTS_URL} element={<Events />} />
      <Route path={Urls.COMMUNITY_URL} element={<Community />} />
      <Route
        path={Urls.COMMUNITY_URL + "/:communityId"}
        element={<CommunityInner />}
      />
      <Route
        path={Urls.COMMUNITY_URL + "/:communityId" + "/:communityDiscussionId"}
        element={<CommunityDiscussionInner />}
      />
      <Route
        path={
          Urls.COMMUNITY_URL +
          "/:communityId" +
          "/community-event/:communityEventId"
        }
        element={<CommunityEvent />}
      />
      <Route path={Urls.EVENTS_URL + "/:eventId"} element={<EventsInner />} />
      <Route path={Urls.LIBRARY_URL} element={<Library />} />
      <Route
        path={Urls.LIBRARY_URL + "/:categoryId"}
        element={<CategoryInner />}
      />
      <Route
        path={Urls.LIBRARY_URL + "/mydev" + "/:myDevId"}
        element={<MyDevPlaylist />}
      />
      <Route path={Urls.SETTINGS_URL} element={<Settings />} />
      <Route path={Urls.NOTIFICATIONS_URL} element={<Notifications />} />
      <Route path={Urls.MESSAGES_URL} element={<Messages />} />
      <Route path={Urls.PROFILE_URL} element={<ProfileLayout />}>
        <Route
          path=""
          element={<Navigate to={Urls.POSTS_URL + "/:userId"} replace />}
        />
        <Route path={Urls.POSTS_URL + "/:userId"} element={<Posts />} />
        <Route
          path={Urls.PROFILE_ABOUT_URL + "/:userId"}
          element={<ProfileAbout />}
        />
        <Route
          path={Urls.INTERESTED_EVENTS_URL + "/:userId"}
          element={<InterestedEvents />}
        />
        <Route
          path={Urls.FOLLOWED_COMMUNITIES_URL + "/:userId"}
          element={<FollowedCommunities />}
        />
      </Route>
      <Route
        path={Urls.SEARCH_RESULTS_URL + "/:query"}
        element={<SearchResults />}
      />
    </Routes>
  );
};

export default PriveRoots;
