import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import { Button, RenderIf } from "shared/components";
import { useUpdateCourseRatingMutation } from "redux/api/library";
import { labels } from "../RateModal/data";
import "../RateModal/Modal.scss";

const initialValues = {
  rating: "",
};

const EditRateModal = ({ hubDevCourseId, show, onHide, rating }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState();
  const [updateRateCourse, { isSuccess, isLoading }] =
    useUpdateCourseRatingMutation();

  const formik = useFormik({
    initialValues,
    onSubmit: () => {
      const formData = new FormData();
      formData.append("HubDevCourseId", hubDevCourseId);
      formData.append("Rating", Number(value));
      updateRateCourse(formData);
    },
  });
  useEffect(() => {
    if (isSuccess) {
      onHide();
    }
  }, [isLoading]);
  useEffect(() => {
    if (rating) {
      setValue(rating);
    }
  }, [rating]);
  return (
    <Modal keyboard={false} backdrop="static" show={show} onHide={onHide}>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{t("editRating")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="rateModalBody">
          <Box
            sx={{
              width: 200,
              display: "flex",
              alignItems: "center",
            }}>
            <Rating
              name="text-feedback"
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
            />
            <Box sx={{ ml: 2 }}>{labels[value]}</Box>
          </Box>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="pt_14_26 font_14 align-items-center d-flex"
            background="#057953"
            type="submit"
            disabled={isLoading || !value}>
            {t("rate")}
            <RenderIf condition={isLoading}>
              <div class="spinner-border" role="status"></div>{" "}
            </RenderIf>
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditRateModal;
